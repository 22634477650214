import { css } from "styled-components"

import { minimumGray } from "src/ui/colors"

export const shadowBottom = css`
  border-bottom: ${minimumGray};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`

export const shadowTop = css`
  border-top: ${minimumGray};
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.06);
`

export const cardBoxShadow = css`
  box-shadow:
    rgb(0 0 0 / 4%) 0px 0px 12px 6px,
    rgb(0 0 0 / 3%) 0px 0px 6px 3px;
`
